import React, { createContext, useState } from 'react';
import { auth, db } from '../../firebase';

/**
 * This provider is created
 * to access user in whole app
 */

export const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  return (
    <AuthContext.Provider
      value={{
        user,
        setUser,
        login: async (email, password) => {
          try {
            await auth.signInWithEmailAndPassword(email, password);            
          } catch (e) {
            console.log(e);
          }
        },
        register: async (email, password, plate) => {
          try {
            const cleanedPlate = plate.replace(/[^a-zA-Z0-9üäöÜÄÖ]/g, '').toUpperCase();
            const carcheck = await db.collection('cars').where("plate", "==", cleanedPlate).get();
            if(!carcheck.empty){
              alert("Kennzeichen existiert bereits!");
              return null;
            }
            const userres = await auth.createUserWithEmailAndPassword(email, password);            
            const carres = await db.collection('cars').add({ plate: cleanedPlate , user: userres.user.uid});
            const car = await carres.get();
            userres.user.cars = [];
            userres.user.cars.push({ "id": car.id, "plate" : car.data().plate });
            return userres;            
          } catch (e) {
            console.log(e);
          }
        },
        logout: async () => {
          try {
            await auth.signOut();
          } catch (e) {
            console.error(e);
          }
        }
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
