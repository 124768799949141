import React, { useState, useContext } from 'react';
import { View, StyleSheet } from 'react-native';
import { Title, IconButton } from 'react-native-paper';
import FormInput from '../components/FormInput';
import FormButton from '../components/FormButton';
import { AuthContext } from '../navigation/AuthProvider';
import { TextInput } from 'react-native-paper';

export default function SignupScreen({ navigation }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [plate, setPlate] = useState('');

  const { register } = useContext(AuthContext);

  return (
    <View style={styles.container}>
      <Title style={styles.titleText}>Registrieren bei Motochat</Title>
      <FormInput
        labelName='Email'
        value={email}
        autoCapitalize='none'
        right={<TextInput.Icon name="email" />}
        onChangeText={userEmail => setEmail(userEmail)}
      />
      <FormInput
        labelName='Passwort'
        value={password}
        secureTextEntry={true}
        right={<TextInput.Icon name="lock" />}
        onChangeText={userPassword => setPassword(userPassword)}
      />
      <FormInput
        labelName='Kennzeichen'
        value={plate}
        right={<TextInput.Icon name="car" />}
        onChangeText={userPlate => setPlate(userPlate)}
      />
      <FormButton
        title='Registrieren'
        modeValue='contained'
        labelStyle={styles.loginButtonLabel}
        onPress={() => register(email, password, plate)}
        disabled={email.length === 0 || password.length < 6 || plate.length <= 2}
      />
      <IconButton
        icon='keyboard-backspace'
        size={30}
        style={styles.navButton}
        color='#6646ee'
        onPress={() => navigation.goBack()}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#f5f5f5',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center'
  },
  titleText: {
    fontSize: 24,
    marginBottom: 10
  },
  loginButtonLabel: {
    fontSize: 22
  },
  navButtonText: {
    fontSize: 18
  },
  navButton: {
    marginTop: 10
  }
});
