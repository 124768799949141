import React, { useState, useContext } from 'react';
import { View, StyleSheet } from 'react-native';
import { IconButton, Title } from 'react-native-paper';
import { db } from '../../firebase';
import FormInput from '../components/FormInput';
import FormButton from '../components/FormButton';
import useStatsBar from '../utils/useStatusBar';
import { AuthContext } from '../navigation/AuthProvider';

export default function AddRoomScreen({ navigation }) {
  useStatsBar('dark-content');
  const [plate, setPlate] = useState('');
  const { user } = useContext(AuthContext);

  /**
   * Create a new Firestore collection to save threads
   */
  function handleButtonPress() {
    if (plate.length > 2) {
      let inputPlate = plate.replace(/[^a-zA-Z0-9üäöÜÄÖ]/g, '').toUpperCase();
      let userPlate = user.cars[0].plate;
      db
        .collection('THREADS')
        .add({
          name: userPlate + "-" + inputPlate,
          plates: [userPlate, inputPlate],
          latestMessage: {
            text: '',
            createdAt: new Date().getTime()
          }
        })
        .then(docRef => {
          navigation.navigate('Home');
        });
    }
  }
  return (
    <View style={styles.rootContainer}>
      <View style={styles.closeButtonContainer}>
        <IconButton
          icon='close-circle'
          size={36}
          color='#6646ee'
          onPress={() => navigation.goBack()}
        />
      </View>
      <View style={styles.innerContainer}>
        <Title style={styles.title}>Chat starten</Title>
        <FormInput
          labelName='Kennzeichen'
          value={plate}
          onChangeText={userPlate => setPlate(userPlate)}
          clearButtonMode='while-editing'
        />
        <FormButton
          title='Starten'
          modeValue='contained'
          labelStyle={styles.buttonLabel}
          onPress={() => handleButtonPress()}
          disabled={plate.length <= 2}
        />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  rootContainer: {
    flex: 1
  },
  closeButtonContainer: {
    position: 'absolute',
    top: 30,
    right: 0,
    zIndex: 1
  },
  innerContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center'
  },
  title: {
    fontSize: 24,
    marginBottom: 10
  },
  buttonLabel: {
    fontSize: 22
  }
});
