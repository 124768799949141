// Import the functions you need from the SDKs you need
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAGMADNBnE2CZD79YRbWflGGATM1Cz-5vI",
  authDomain: "motochat-5fcd6.firebaseapp.com",
  projectId: "motochat-5fcd6",
  storageBucket: "motochat-5fcd6.appspot.com",
  messagingSenderId: "320505598260",
  appId: "1:320505598260:web:e9d6814f31ae1b9499d561"
};

let app;
if (firebase.apps.length === 0) {
    app = firebase.initializeApp(firebaseConfig);
} else {
    app = firebase.app();
}

const db = app.firestore();
const auth = firebase.auth();

export { db, auth };